<template>
  <div>
    <TosListVuetify serviceName="gateway" entityName="routes-out" :headers="headers">
      <template #customFilters="slotProps">
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <v-text-field :label="tLabel('Name')" v-model="slotProps.data.name" />
            </v-col>
          </v-row>
        </v-container>
      </template>


      <template #editF="slotProps">
        <div v-if="slotProps.data" class="p-cardialog-content">
          <v-row>
            <v-col cols="3">
              <v-text-field :label="tLabelRequired('name')" v-model="slotProps.data.name" :rules="[rules.required]"
                :error-messages="slotProps.errors.name" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabelRequired('enabled (Y/N)')" v-model="slotProps.data.enabled"
                :rules="[rules.required]" :error-messages="slotProps.errors.required" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('company')" v-model="slotProps.data.company"
                :error-messages="slotProps.errors.company" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-select :label="tLabel('Protocol IN')" v-model="slotProps.data.protocolIn"
                :items="getData(guiData.routeProtocolsIn)" :error-messages="slotProps.errors.protocolIn" clearable
                hint="protocollo messaggio/evento in (es. TOPIC, REST)" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('ProtocolIn Par')" v-model="slotProps.data.protocolInPar"
                :error-messages="slotProps.errors.protocolInPar"
                hint="parametri protocollo/messaggio in (es nome topic, URL API, altro)" />
            </v-col>
            <v-col cols="3">
              <v-combobox :label="tLabel('sourceEvent')" v-model="slotProps.data.sourceEvent"
                :items="getSourceEvents(slotProps.data)" :error-messages="slotProps.errors.sourceEvent" clearable
                hint="Tipologia di messaggio o evento scatenante (gate out convoglio, scarico treno, distinta treno out, ecc)" />
            </v-col>

          </v-row>

          <v-row>
            <v-col cols="3">
              <v-select :label="tLabel('Protocol')" v-model="slotProps.data.protocol"
                :items="getData(guiData.routeProtocols)" :error-messages="slotProps.errors.protocol" clearable
                hint="protocollo (SFTP, REST API, email, SOAP, ecc)" />
            </v-col>

            <v-col cols="9">
              <v-text-field :label="tLabel('Endpoint')" v-model="slotProps.data.endpoint"
                :error-messages="slotProps.errors.endpoint" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field :label="tLabel('Username')" v-model="slotProps.data.authUsername"
                :error-messages="slotProps.errors.authUsername" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('Password')" v-model="slotProps.data.authPassword"
                :error-messages="slotProps.errors.authPassword" />
            </v-col>
            <v-col cols="3">
              <v-select :label="tLabel('Auth type')" v-model="slotProps.data.authType"
                :items="getData(guiData.routeAuthTypes)" :error-messages="slotProps.errors.authType" clearable />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-text-field :label="tLabel('DocumentType')" v-model="slotProps.data.documentType"
                :error-messages="slotProps.errors.documentType" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('FileName')" v-model="slotProps.data.fileName"
                :error-messages="slotProps.errors.fileName" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-select :label="tLabel('ProcessorName')" v-model="slotProps.data.processorName"
                :items="getData(guiData.processors)" :error-messages="slotProps.errors.processorName" clearable />
            </v-col>
            <v-col cols="3">
              <v-select :label="tLabel('MessageFormat')" v-model="slotProps.data.messageFormat"
                :items="getMessageFormats(slotProps.data)" :error-messages="slotProps.errors.messageFormat" clearable />
            </v-col>

            <v-col cols="3">
              <v-text-field :label="tLabel('processorParameters')" v-model="slotProps.data.processorParameters"
                :error-messages="slotProps.errors.processorParameters" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field :label="tLabel('filterKey')" v-model="slotProps.data.filterKey"
                :error-messages="slotProps.errors.filterKey" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('filterValue')" v-model="slotProps.data.filterValue"
                :error-messages="slotProps.errors.filterValue" />
            </v-col>

          </v-row>
        </div>
      </template>

    </TosListVuetify>

  </div>

</template>


<script>
import TosListVuetify from '@/components/anagrafiche/TosListVuetify';
import FormatsMixins from "@/mixins/FormatsMixins";

export default {
  name: "Rules",
  data() {
    return {
      guiData: {},
      headers: [
        { text: "", value: "action" },
        { text: `${this.tLabel('name')}`, value: "name" },
        { text: `${this.tLabel('enabled')}`, value: "enabled" },
        { text: `${this.tLabel('company')}`, value: "company" },
        { text: `${this.tLabel('protocolIn')}`, value: "protocolIn" },
        //{ text: `${this.tLabel('protocolIn Par')}`, value: "protocolInParameters" },
        { text: `${this.tLabel('sourceEvent')}`, value: "sourceEvent" },
        { text: `${this.tLabel('protocol')}`, value: "protocol" },
        //{ text: `${this.tLabel('authType')}`, value: "authType" },
        //{ text: `${this.tLabel('authUsername')}`, value: "authUsername" },
        //{ text: `${this.tLabel('authPassword')}`, value: "authPassword" },
        //{ text: `${this.tLabel('authUrl')}`, value: "authUrl" },
        { text: `${this.tLabel('endpoint')}`, value: "endpoint" },
        { text: `${this.tLabel('processorName')}`, value: "processorName" },
        //{ text: `${this.tLabel('processorParameters')}`, value: "processorParameters" },
        { text: `${this.tLabel('messageFormat')}`, value: "messageFormat" },
        { text: `${this.tLabel('documentType')}`, value: "documentType" },
        { text: `${this.tLabel('fileName')}`, value: "fileName" },
        { text: `${this.tLabel('filterKey')}`, value: "filterKey" },
        { text: `${this.tLabel('filterValue')}`, value: "filterValue" },
      ],
    };
  },
  mounted(){
    this.loadCombo();
  },
  
  methods: {
    async prepareItem(data) {
    },
    async loadCombo() {
      this.guiData = await this.$api.get(this.$apiConfiguration.BASE_PATH_GATEWAY + "gui-helper");
    },
    getData(s) {
      if (s == undefined) return null;
      try {
        return JSON.parse(s);
      } catch (e) {
        console.log("Error parsing getData(" + s + ")");
      }
    },
    getMessageFormats(data) {
      if (!data.processorName) return [];
      const key = "processorFormats." + data.processorName;
      return this.getData(this.guiData[key]);
    },
    getSourceEvents(data) {
      console.log("getSourceEvent " + data.protocolIn)
      if (!data.protocolIn) return [];
      const key = "sourceEvents." + data.protocolIn;
      return this.getData(this.guiData[key]);
    },

  },
  components: {TosListVuetify},
  mixins: [FormatsMixins],
};
</script>
